import React, { useEffect } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import { SEO } from "components"

const FourWrapper = styled.div`
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--whiteChalk);
`

const NotFoundPage = () => {
  useEffect(() => setTimeout(() => navigate("/"), 1000), [])

  return (
    <>
      <SEO title="Not Found" />
      <FourWrapper>Sorry, The page not found!</FourWrapper>
    </>
  )
}
export default NotFoundPage
